<template>
  <v-container>
    <go-back-btn :routeToGo="routeToGo" />
    <page-header class="mb-3" :title="title" />
    <v-row>
      <!-- Filtros -->
      <v-col cols="12">
        <v-card flat class="mx-auto my-12 mt-2 mb-2" max-width="1200" outlined>
          <v-card-text>
            <v-form
              v-model="isFormValid"
              ref="corte-form"
              id="corte-form"
              @submit.prevent="applyFilters()">
              <v-row>
                <v-col cols="8" class="py-0">
                  <v-select
                    v-model="usuariosHyD"
                    dense
                    outlined
                    multiple
                    :items="usuarioItems"
                    item-text="text"
                    item-value="value"
                    autocomplete="on"
                    clearable
                    label="Usuario">
                    <template v-slot:prepend-item>
                      <v-list-item ripple @click="usuariosToggle()">
                        <v-list-item-action>
                          <v-icon
                            :color="usuariosHyD.length > 0 ? 'primary' : ''">
                            {{ multiselectIconUsuarios }}
                          </v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title> Todos </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-divider class="mt-2"></v-divider>
                    </template>
                    <template v-slot:selection="{ item, index }">
                      <v-chip class="mt-1" v-if="index === 0">
                        <span>{{ item.text }}</span>
                      </v-chip>
                      <span v-if="index === 1" class="grey--text caption">
                        (+{{ usuariosHyD.length - 1 }} otros)
                      </span>
                    </template>
                  </v-select>
                </v-col>
                <!-- Aplicar -->
                <v-col cols="4" align="end">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        class="mr-2"
                        v-bind="attrs"
                        v-on="on"
                        size="28"
                        @click="resetForm">
                        {{ clearFiltersIcon }}
                      </v-icon>
                    </template>
                    <span>Limpiar filtros</span>
                  </v-tooltip>
                  <v-btn
                    color="primary"
                    :disabled="!isFormValid"
                    elevation="2"
                    small
                    type="submit"
                    form="corte-form">
                    Aplicar
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- Tabla -->
    <v-data-table
      :headers="headers"
      :items="usuarios"
      :search="search"
      :footer-props="{ 'items-per-page-text': 'Usuarios por página' }"
      item-key="userId"
      class="elevation-1"
      :loading="isLoading">
      <template v-slot:[`item.userGroups`]="{ item }">
        <ul v-if="item.userGroups.length > 0" class="pl-0">
          <li v-for="userGroup in item.userGroups" :key="userGroup.id">
            {{ userGroup.userGroupNom }}
          </li>
        </ul>
        <span v-else> No hay datos </span>
      </template>
      <template v-slot:[`item.usuDeshabilitado`]="{ item }">
        <v-icon
          v-if="item.usuDeshabilitado"
          small
          color="error"
          class="d-flex justify-center">
          {{ iconDeshabilitado }}
        </v-icon>
      </template>
      <template v-slot:top>
        <v-toolbar flat>
          <v-row>
            <v-col cols="6" class="p-0">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Buscar usuario"
                single-line
                hide-details></v-text-field>
            </v-col>

            <v-col cols="4" class="p-0 m-1">
              <v-btn
                color="primary"
                class="to-right"
                @click="configurarPermisos()">
                Configuracion permisos
              </v-btn>
            </v-col>
            <v-col cols="1" class="p-0"></v-col>
            <v-col cols="1" class="p-0">
              <v-btn color="primary" class="to-right" @click="openModal()">
                Nuevo
              </v-btn>
            </v-col>
          </v-row>
        </v-toolbar>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-bind="attrs"
              v-on="on"
              class="mr-2"
              @click="openModal(item)">
              mdi-pencil
            </v-icon>
          </template>
          <span>Editar usuario</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on" @click="deleteItem(item)">
              mdi-delete
            </v-icon>
          </template>
          <span>Eliminar usuario</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-if="!item.usuDeshabilitado"
              v-bind="attrs"
              v-on="on"
              @click="restablecerContraseña(item)">
              {{ iconCandado }}
            </v-icon>
          </template>
          <span>Restablecer contraseña</span>
        </v-tooltip>
      </template>
      <template v-slot:no-data>
        <v-btn color="primary" @click="applyFilters"> Resetear </v-btn>
      </template>
    </v-data-table>

    <v-dialog
      v-if="openModalEdit"
      v-model="openModalEdit"
      max-width="70%"
      @keydown.esc="closeAndReload">
      <EditUsuarioYPermisos
        :usuarioEditItem="usuarioEditItem"
        @closeAndReload="closeAndReload"
        @confirmDeshabilitar="confirmDeshabilitar"></EditUsuarioYPermisos>
    </v-dialog>
    <ChangeAllUsersPassword
      :openDialog.sync="changeAllUsersPassword"
      :isLoading="changePasswordLoadingBtn"
      @confirmChange="confirmChangePassword($event)" />
    <DeleteDialog
      :titleProp="'¿Eliminar usuario?'"
      :isLoading="loadingDeleteBtn"
      :openDelete.sync="dialogDelete"
      @onDeleteItem="confirmDeleteUsuario()" />

    <v-row justify="center">
      <v-dialog v-model="dialog" persistent max-width="40%">
        <v-card>
          <v-card-title>
            <span class="pl-1 primary--text"
              >Restablecer contraseña de: {{ this.nomUsu }}</span
            >
          </v-card-title>
          <v-card-text>
            <v-form
              v-model="isFormValid"
              ref="form"
              form="form"
              id="form"
              @submit.prevent="save()">
              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    tabindex="2"
                    outlined
                    dense
                    placeholder=" "
                    label="Contraseña"
                    :append-icon="userPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    @click:append="() => (textType = !textType)"
                    :type="textType ? 'password' : 'text'"
                    :rules="rules.password"
                    v-model="userPassword"
                    @focus="inputActive = 'userPassword'"
                    @blur="inputActive = ''"></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn outlined @click="closeModalRestablecer()"> Cancelar </v-btn>
            <v-btn
              color="primary"
              type="submit"
              :disabled="!isFormValid"
              form="form"
              :loadingBtn="loadingBtn">
              Guardar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
import PageHeader from "@/components/ui/PageHeader";
import GoBackBtn from "@/components/shared/GoBackBtn";
import rules from "@/utils/helpers/rules";
import { mask } from "vue-the-mask";
import EditUsuarioYPermisos from "./EditUsuarioYPermisos.vue";
import ChangeAllUsersPassword from "./ChangeAllUsersPassword.vue";
import enums from "@/utils/enums/index.js";
import DeleteDialog from "@/components/shared/DeleteDialog";

export default {
  components: {
    PageHeader,
    GoBackBtn,
    EditUsuarioYPermisos,
    ChangeAllUsersPassword,
    DeleteDialog,
  },
  directives: { mask },
  name: "Usuarios",

  data() {
    return {
      isLoading: false,
      loadingBtn: false,
      dialog: false,
      expanded: [],
      isFormValid: false,
      clearFiltersIcon: enums.icons.CLEAR_FILTERS,
      rules: rules,
      usuarios: [],
      userPassword: null,
      textType: String,
      configIcon: enums.icons.SETTINGS,
      iconCandado: enums.icons.PADLOCK,
      iconDeshabilitado: enums.icons.CIRCLE_NONE,
      usuarioItems: [
        {
          text: "Habilitados",
          align: "start",
          sortable: false,
          value: 1,
        },
        {
          text: "Deshabilitados",
          align: "start",
          sortable: false,
          value: 2,
        },
      ],
      usuariosHyD: 0,
      search: "",
      dialogDelete: false,
      routeToGo: "/",
      title: "Usuarios y permisos",
      headers: [
        {
          text: "Nombre",
          align: "start",
          sortable: false,
          value: "nombre",
        },
        {
          text: "Email",
          align: "start",
          sortable: false,
          value: "userEmail",
        },
        {
          text: "Usuario",
          align: "start",
          sortable: false,
          value: "userNom",
        },
        {
          text: "Rol",
          align: "start",
          sortable: false,
          value: "userGroups",
        },
        {
          text: "Usuario Deshabilitado",
          align: "center",
          sortable: false,
          value: "usuDeshabilitado",
        },

        { text: "Acciones", value: "actions", sortable: false, align: "end" },
      ],
      usuarioDeshabilitado: false,
      openModalEdit: false,
      userGroups: [],
      usuarioEditItem: {},
      canConfig: true,
      changeAllUsersPassword: false,
      changePasswordLoadingBtn: false,
      loadingDeleteBtn: false,
      nomUsu: {},
      idUser: null,
      checkBoxBlankOutlineIcon: enums.icons.CHECKBOX_BLANK_OUTLINE,
      mSCloseBoxIcon: enums.icons.CLOSE_BOX,
    };
  },
  created() {
    this.usuariosHyD = [1];
    this.applyFilters();
  },
  computed: {
    multiselectIconUsuarios() {
      if (this.selectAllUsuarios) return this.mSCloseBoxIcon;
      return this.checkBoxBlankOutlineIcon;
    },
    selectAllUsuarios() {
      return (
        this.usuariosHyD && this.usuariosHyD.length === this.usuarioItems.length
      );
    },
  },
  methods: {
    ...mapActions({
      getLicenciasClientes: "clientesData/getClientesAxios",
      deleteUsuario: "usuarios/deleteUsuario",
      restablecerPass: "usuarios/restablecerPass",
      getUsuariosInfo: "usuarios/getUsuariosInfo",
      getGruposUsuario: "usuarios/getGruposUsuario",
      massPasswordChange: "usuarios/massPasswordChange",
      setAlert: "user/setAlert",
    }),
    restablecerContraseña(item) {
      this.userPassword = null;
      this.idUser = item.userId;
      this.dialog = true;
      this.nomUsu = item.nombre;
    },
    closeModalRestablecer() {
      this.dialog = false;
    },
    usuariosToggle() {
      this.$nextTick(() => {
        if (this.selectAllUsuarios) this.usuariosHyD = [];
        else this.usuariosHyD = this.usuarioItems.map((x) => x.value);
      });
    },
    async save() {
      this.loadingBtn = true;
      let data = {
        userId: this.idUser,
        password: this.userPassword,
      };
      const response = await this.restablecerPass(data);
      if (response.status === 200) {
        this.setAlert({
          type: "success",
          message: "Contraseña restablecida con éxito",
        });
        this.applyFilters();
        this.loadingBtn = false;
        this.dialog = false;
      }
    },
    openModal(item) {
      this.openModalEdit = true;
      this.usuarioEditItem = item;
    },
    deleteItem(item) {
      if (item.userGroups != 0) {
        this.setAlert({
          type: "warning",
          message: "No se puede eliminar un usuario con roles asignados.",
        });
        this.loadingDeleteBtn = false;
      } else {
        this.userId = item.userId;
        this.dialogDelete = true;
        this.loadingDeleteBtn = false;
      }
    },
    async confirmDeleteUsuario() {
      this.loadingDeleteBtn = false;
      const response = await this.deleteUsuario(this.userId);
      if (response.status === 200) {
        this.dialogDelete = false;
        this.setAlert({ type: "success", message: "Eliminado con éxito." });
        this.applyFilters();
        //this.changeAllUsersPassword = true;
      }
      this.loadingDeleteBtn = false;
    },
    async confirmChangePassword(newPassword) {
      this.changePasswordLoadingBtn = true;
      const response = await this.massPasswordChange(newPassword);
      if (response.status === 200) {
        this.changeAllUsersPassword = false;
        this.setAlert({
          type: "success",
          message: "Contraseña actualizada con éxito.",
        });
      }
      this.changePasswordLoadingBtn = false;
    },
    closeAndReload() {
      this.openModalEdit = false;
      this.dialogDelete = false;
      this.applyFilters();
    },
    confirmDeshabilitar() {
      this.changeAllUsersPassword = true;
    },
    configurarPermisos() {
      this.$router.push({ name: "Permisos" });
    },
    async applyFilters() {
      // this.$store.state.filtersConsultaFecha == null;
      this.filtrosGuardados = {
        usuario: this.usuariosHyD,
      };
      if (
        this.filtrosGuardados.usuario.length > 1 ||
        this.filtrosGuardados.usuario == null ||
        this.filtrosGuardados.usuario.length == 0
      ) {
        const response = await this.getUsuariosInfo(0);
        this.usuarios = response;
      } else {
        const response = await this.getUsuariosInfo(
          this.filtrosGuardados.usuario[0]
        );
        this.usuarios = response;
      }
      this.isLoading = false;
    },
    resetForm() {
      this.usuariosHyD = [];
    },
  },
};
</script>
<style scoped>
::v-deep .myLabel {
  color: white;
}
.to-right {
  float: right;
}
ul {
  list-style: none;
}
</style>
